import { lazy, Suspense } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import { getCountry } from "./utils.js/getCountry";

import ScrollToTop from "./utils.js/scrollToTop";

import SuspenseFallback from "./components/SuspenseFallback/SuspenseFallback";

// lazy loading Pages
// const Homepage = lazy(() => import("./Pages/Homepage"));
// const AboutUs = lazy(() => import("./Pages/AboutUs/AboutUs"));
const PrivacyPolicy = lazy(() => import("./Pages/PrivacyPolicy"));
const TermsAndCondition = lazy(() => import("./Pages/TermsAndCondition"));
// const DownloadHairdays = lazy(() => import("./Pages/DownloadHairdays"));

// // lazy loading component
// const WatingListModalGoogle = lazy(() =>
//   import("./components/Modals/WatingListModalGoogle")
// );
// const WatingListModalApple = lazy(() =>
//   import("./components/Modals/WatingListModalApple")
// );

function App() {
  // useEffect(() => {
  //   getCountry();
  // }, []);

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Suspense fallback={SuspenseFallback}>
          <Routes>
            {/* <Route exact path="/" element={<Homepage />} />
            <Route exact path="/about-us" element={<AboutUs />} /> */}
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              exact
              path="/terms-and-conditions"
              element={<TermsAndCondition />}
            />
            {/* <Route
              exact
              path="/download-hairdays"
              element={<DownloadHairdays />}
            /> */}
          </Routes>
        </Suspense>
      </Router>
      {/* <WatingListModalGoogle />
      <WatingListModalApple /> */}
    </div>
  );
}

export default App;
